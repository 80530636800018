import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO/SEO';
import Layout from '../components/shared/layout';
import { wrapper1224 } from '../utils/style';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import ButtonLink from '../components/shared/ButtonLink';

const Wrapper = styled('div')`
  ${wrapper1224}
  margin: 30px auto;
  p {
    margin-bottom: 1rem;
  }
`;

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title='404: Not found'/>
    <Wrapper>
      <div className="flex">
        <div className="col-2">
          {data.missing.childImageSharp.fluid ?
            <Img alt="404" fluid={data.missing.childImageSharp.fluid}/>
            : "404"
          }
        </div>
        <div className="col-2">
          <h1>We couldn't find the page that was searched.</h1>
          <p>You may have entered the wrong link. Or incorrect address was entered.</p>
          <ButtonLink to="/" text="Return to the main page" ariaText="Return to the main page"/>
        </div>
      </div>

    </Wrapper>
  </Layout>
);

export default NotFoundPage;

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    missing: file(relativePath: { eq: "404.png" }) {
      childImageSharp {
          fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }   
  }
`;
